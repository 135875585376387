import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Baseurl,Imageurl,  } from "../../config/BaseUrl";
import axios from 'axios'
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from 'react-redux';
import { CaseActions, UploadChasisImage, UploadFrontImage, UploadOpenBonnet, UploadRearUnderPart, UploadRight45Image, selfieWithVehicleUpload, uploadAnyDamage, uploadExtraImage, uploadExtraImage1, uploadExtraImage2, uploadExtraImage3, uploadFrontUnderCar, uploadGlassInsideImage, uploadGlassOutside, uploadLeft45, uploadLeftImage, uploadOdometerImage, uploadOpenDickey, uploadRcBackImage, uploadRcFront, uploadRearImage, uploadRightImage, uploadRpmImage, uploadSignature, uploadTyreImage, uploadformatImage } from '../../redux/Cases/CaseSlice';
import { CloudinaryContext, Video } from 'cloudinary-react';
import Spinner from 'react-bootstrap/Spinner';
import { saveAs } from 'file-saver';
import { FiDownload } from "react-icons/fi";
import { FaUpload } from 'react-icons/fa';

const CaseImages = () => {
    const params = useParams()
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const {caseImages}=useSelector((store)=>store.cases)
    const [assetType, setAssetType] = useState('')
    const [videoRecord, setVideoRecord] = useState('')
    const [selfieWithVehicle, setSelfieWithvehicle] = useState('');
    const [chasisImage, setChasisImage] = useState('');
    const [formatImage, setFormatImage] = useState('');
    const [openBonnetImage, setOpenBonnetImage] = useState('');
    const [right45Image, setRight45Image] = useState('');
    const [frontImage, setFrontImage] = useState('');
    const [frontUnderCarImage, setFrontUnderCarImage] = useState('');
    const [leftFront45Image, setLeftFront45Image] = useState('');
    const [leftImage, setLeftImage] = useState('');
    const [rearImage, setRearImage] = useState('');
    const [rearUnderImage, setRearUnderImage] = useState('');
    const [openDickeyImage, setOpenDickeyImage] = useState('');
    const [rightImage, setRightImage] = useState('');
    const [tyreImage, setTyreImage] = useState('');
    const [wsGlassOutsideImage, setWsGlassOutsideImage] = useState('');
    const [wsGlassInsideImage, setWsGlassInsideImage] = useState('');
    const [odometerImage, setOdometerImage] = useState('');
    const [rpmImage, setRpmImage] = useState('');
    // const [vinPlateImage, setVinPlateImage] = useState('');
    const [anyDamageImage, setAnyDamageImage] = useState('');
    const [extraImage, setExtraImage] = useState('');
    const [extraImage1, setExtraImage1] = useState('');
    const [extraImage2, setExtraImage2] = useState('');
    const [extraImage3, setExtraImage3] = useState('');
    const [signatureImage, setSignatureImage] = useState('');
    const [rcFrontImage, setRcFrontImage] = useState('');
    const [rcBackImage, setRcBackImage] = useState('');
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [showLoader, setShowLoader] = useState(false)
    // ------*****-----------errors-----------*****------
    useEffect(() => {
        const fetchData = async () => {
            if (caseImages) {
                const data = caseImages
                setAssetType(data.assetType)
                setVideoRecord(data.videos);
                setSelfieWithvehicle(data.images.selfieWithVehicle);
                setChasisImage(data.images.chasisImage);
                setFormatImage(data.images.format);
                setOpenBonnetImage(data.images.openBonnet);
                setRight45Image(data.images.right45);
                setFrontImage(data.images.frontImage);
                setFrontUnderCarImage(data.images.frontImage);
                setLeftFront45Image(data.images.left45);
                setLeftImage(data.images.leftImage);
                setRearImage(data.images.rearImage);
                setRearUnderImage(data.images.rearUnderPart);
                setOpenDickeyImage(data.images.openDickey);
                setRightImage(data.images.rightImage);
                setTyreImage(data.images.tyreImage);
                setWsGlassOutsideImage(data.images.glassOutside);
                setWsGlassInsideImage(data.images.glassInside);
                setOdometerImage(data.images.odometerImage);
                setRpmImage(data.images.rpmImage);
                setAnyDamageImage(data.images.anyDamage);
                setExtraImage(data.images.extraImage);
                setExtraImage1(data.images.extraImage1);
                setExtraImage2(data.images.extraImage2);
                setExtraImage3(data.images.extraImage3);
                setSignatureImage(data.signNdoc.sign);
                setRcFrontImage(data.signNdoc.rcFront);
                setRcBackImage(data.signNdoc.rcBack);
            }
        };
        fetchData();
    }, [caseImages]);


    return (
        <>
            <div className="min-h-screen flex flex-col w-full">
                <div className="p-2 space-y-6">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div className="flex items-center justify-between py-2 px-5"
                            style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                        >
                            <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
                                Case Images 
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-3 gap-4">
                                {/* <div className="col-span-3 space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Video *
                                    </label>
                                    {
                                        showLoader === true ? (<>
                                            <div style={{ height: '250px', width: '50%', backgroundColor: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                        </>) : (<>
                                            <iframe
                                                title="Video Player"
                                                width="50%" // Set width to 100% to fill the container
                                                height="250px" // Set height to auto to maintain aspect ratio
                                                src={videoRecord}
                                                frameBorder="0"
                                                allowFullScreen
                                            ></iframe>
                                        </>)
                                    }
                                    <input type="file" id='videouploading' onChange={handleFileChange} accept="video/*" />
                                    {
                                        selectedVideo ? (
                                            <div>
                                                <button onClick={handleUpload} style={{ backgroundColor: '#ff6600', color: '#fff', padding: '5px 8px', borderRadius: '4px' }}>Upload Video</button>
                                            </div>) : (
                                            <div>
                                                <button style={{ backgroundColor: '#ff6600', opacity: '.5', color: '#fff', padding: '5px 8px', borderRadius: '4px' }}>Upload Video</button>
                                            </div>)
                                    }


                                </div> */}
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Selfie With vehicle *
                                    </label>

                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='selfieWithVehicle' src={selfieWithVehicle} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Chasis Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='chasisImage' src={chasisImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Format Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='formatImage' src={formatImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Bonnet Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='openBonnetImage' src={openBonnetImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Right 45* Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='right45Image' src={right45Image} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='frontImage' src={frontImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Under Car Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='frontUnderCarImage' src={frontUnderCarImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Left Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='leftImage' src={leftImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Left 45* Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='leftFront45Image' src={leftFront45Image} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rear Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='rearImage' src={rearImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rear Under Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='rearUnderImage' src={rearUnderImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Dickey Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='openDickeyImage' src={openDickeyImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Right Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='rightImage' src={rightImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Tyre Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='tyreImage' src={tyreImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Ws Glass Outside Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='wsGlassOutsideImage' src={wsGlassOutsideImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Ws Glass Inside Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='wsGlassInsideImage' src={wsGlassInsideImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Odometer Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='odometerImage' src={odometerImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rpm Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='rpmImage' src={rpmImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Any Damage Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='anyDamageImage' src={anyDamageImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='extraImage' src={extraImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 1*
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='extraImage1' src={extraImage1} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 2*
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='extraImage2' src={extraImage2} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 3*
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='extraImage3' src={extraImage3} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Signature Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='signatureImage' src={signatureImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rc Front Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='rcFrontImage' src={rcFrontImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CaseImages