import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import axios from 'axios';
import { Baseurl,Imageurl,  } from "../../config/BaseUrl";;
const initialState = {
  name: '',
  loginData: '',
  phone:'',
  email: '',
  totalClients: [],
  clientLoading:true,
  totalrevenue: localStorage.getItem("totalrevenue") || 0, 
  totalCost:localStorage.getItem("totalcost") || 0, 
};

export const createClient=createAsyncThunk(
  "Client/clientCreate",
  async(formData,thunkAPI)=>{
    try{
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url=`${Baseurl}/api/v1/clients/new`
      const response = await axios.post(url,formData,config);
      console.log(response.data,'response');
      return response.data;
    }catch(error){
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)
export const allClient=createAsyncThunk(
  "Client/allClient",
  async(thunkAPI)=>{
    try{
      const url=`${Baseurl}/api/v1/clients/all`;
      const response = await axios.get(url);
      console.log(response.data,'response');
      return response.data;
    }catch(error){
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)
export const clientMobileExists=createAsyncThunk(
  "Client/mobileExists",
  async(mobile,thunkAPI)=>{
    try{
      const url = `${Baseurl}/api/v1/clients/mobile/${mobile}`;
      const resp = await axios.get(url);
      console.log(resp.data);
      return resp.data;
    }catch(error){
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)
export const clientEmailExists=createAsyncThunk(
  "Client/emailExists",
  async(email,thunkAPI)=>{
    try{
      const url = `${Baseurl}/api/v1/clients/email/${email}`;
      const resp = await axios.get(url);
      console.log(resp.data);
      return resp.data;
    }catch(error){
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)
export const updateClient=createAsyncThunk(
  "Client/updateClient",
  async(formData,thunkAPI)=>{
    try{
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url=`${Baseurl}/api/v1/clients/updateclient/${formData._id}`;
      const response = await axios.put(url,formData,config);
      console.log(response.data,'response');
      return response.data;
    }catch(error){
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)
export const deleteClient=createAsyncThunk(
  "Client/deleteClient",
  async(formData,thunkAPI)=>{
    try{
      const url=`${Baseurl}/api/v1/clients/updateclient/${formData._id}`;
      const response = await axios.delete(url);
      console.log(response.data,'response');
      return response.data;
    }catch(error){
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)
const ClientAuthenticationSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setRevenue(state,action){
      state.totalrevenue=action.payload
      localStorage.setItem("totalrevenue",state.totalrevenue)
    },
    setCost(state,action){
      state.totalCost=action.payload
      localStorage.setItem("totalcost",state.totalCost)
    }
  },
  extraReducers:(buildcase)=>{
    buildcase
    .addCase(createClient.pending,(state)=>{
      state.clientLoading=true
    })
    .addCase(createClient.fulfilled,(state,action)=>{
      state.clientLoading=false
      state.totalClients=[action.payload.client,...state.totalClients]
    })
    .addCase(createClient.rejected,(state)=>{
      state.clientLoading=true
    })
    .addCase(allClient.pending,(state)=>{
      state.clientLoading=true
    })
    .addCase(allClient.fulfilled,(state,action)=>{
      state.clientLoading=false
      state.totalClients=action.payload.clients
    })
    .addCase(allClient.rejected,(state)=>{
      state.clientLoading=true
    })
    .addCase(updateClient.pending,(state)=>{
      state.clientLoading=true
    })
    .addCase(updateClient.fulfilled,(state,action)=>{
      state.clientLoading=false
      state.totalClients=state.totalClients.filter((indClient)=>
        indClient!==action.payload.client._id
      );
      state.totalClients=[action.payload.client,...state.totalClients]
    })
    .addCase(updateClient.rejected,(state)=>{
      state.clientLoading=true
    })
    .addCase(deleteClient.pending,(state,action)=>{
      state.clientLoading=true
      state.totalClients=state.totalClients.filter((indClient)=>
        indClient!==action.payload.client._id
      )
    })
  }
});

export const clientActions = ClientAuthenticationSlice.actions;
export default ClientAuthenticationSlice.reducer;
