import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Baseurl,Imageurl,  } from "../../config/BaseUrl";
import axios from 'axios'
import { format } from 'date-fns';
import moment from 'moment/moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { InputAdornment, TextField } from "@mui/material";

const EditReport2 = () => {
    const params = useParams()
    const navigation = useNavigate()
    const { currentCase } = useSelector((state) => state.cases)
    const [loanAgmtNo, setLoanAgmtNo] = useState('');
    const [docVerified, setDocVerified] = useState('');
    const [regOwnerName, setRegOwnerName] = useState('');
    const [regDate, setRegdate] = useState('');
    const [mfgDate, setMfgdate] = useState('');
    const [numberPlateType, setNumberPlateType] = useState('');
    const [regNumber, setRegNumber] = useState('');
    const [engineNo, setEngineNo] = useState('');
    const [chasisNo, setChasisNo] = useState('');
    const [bodyType, setBodyType] = useState('');
    const [meterReading, setMeterReading] = useState('');
    const [fuelType, setFuelType] = useState('');
    const [vehicleColor, setVehicleColor] = useState('');
    const [grossWeight, setGrossWeight] = useState('');
    const [ownerSerial, setOwnerSerial] = useState('');
    const [hypothecationBank, setHypothecationBank] = useState('');
    const [insuranceCompany, setInsuranceCompany] = useState('');
    const [insuranceStatus, setInsuranceStatus] = useState('');
    const [cubicCapacity, setCubicCapacity] = useState('');
    const [seatingCapacity, setSeatingCapacity] = useState('');
    const [exteriorRemark, setExteriorRemark] = useState('');


    useEffect(() => {
        const fetchData = async () => {
            const caseId = params.id;
            const url = `${Baseurl}/api/v1/cases/individualcase/${caseId}`;
            const caseFetch = await axios.get(url);
            const data = caseFetch.data.case
            console.log(data,'data'); 
            setRegdate(data.regdDate);
            setMfgdate(
                data.manufacturingDate,
            );           
            setLoanAgmtNo(data.loanAgreementNo);
            setDocVerified(data.documentVerification);
            setRegOwnerName(data.regdOwner);
            setNumberPlateType(data.numberPlate);
            setRegNumber(data.regdNo);
            setEngineNo(data.engNo);
            setChasisNo(data.chasisNo);
            setBodyType(data.bodyType);
            setMeterReading(data.meterReading);
            setFuelType(data.fueltype);
            setVehicleColor(data.vehicleColor);
            setGrossWeight(data.vehicleWeight);
            setOwnerSerial(data.ownerSerialNo);
            setHypothecationBank(data.hypothecationBank);
            setInsuranceCompany(data.insuranceCompany)
            setInsuranceStatus(data.insuranceStatus)
            setCubicCapacity(data.cubicCapacity);
            setSeatingCapacity(data.seatingCapacity);
            setExteriorRemark(data.extriorRemark);
        }
        fetchData()
    }, [params.id])

    const handleRegdDate = (e) => {
        setRegdate(e.target.value)
    }

    const documents = [
        { name: 'Verified', id: 1 },
        { name: 'Not Verified', id: 2 },
    ];
    const selectDocuementVerifiedTypeChange = (e) => {
        const { value } = e.target;
        setDocVerified(value);
    };
    const number = [
        { name: 'White', id: 1 },
        { name: 'Yellow', id: 2 },
        { name: 'Blue', id: 3 },
        { name: 'Black', id: 4 },
    ];
    const selectNumberPlateTypeChange = (e) => {
        const { value } = e.target;
        setNumberPlateType(value);
    };
    const fourWheelerBody = [
        { name: 'Salsoon', id: 1 },
        { name: 'Sedans', id: 2 },
        { name: 'Hatchbacks', id: 3 },
        { name: 'SUVs', id: 4 },
        { name: 'MUVs', id: 5 },
        { name: 'Coupes', id: 6 },
    ];
    const threeWheelerBody = [
        { name: 'Closed', id: 1 },
    ];
    const farmEquipment = [
        { name: 'Car', id: 1 },
    ];
    const constructionBody = [
        { name: 'Motor Graders', id: 1 },
        { name: 'Trenchers', id: 2 },
        { name: 'Excavators', id: 3 },
        { name: 'Common Dump Trucks', id: 4 },
        { name: 'Crawler Loaders', id: 5 },
        { name: 'Skid Steer Loaders', id: 6 },
        { name: 'Scrapers', id: 7 },
        { name: 'Bulldozers', id: 8 },
        { name: 'Backhoe Loader', id: 9 },
    ];
    const twoWheelerBody = [
        { name: 'standard', id: 1 },
        { name: 'cruiser', id: 2 },
        { name: 'touring', id: 3 },
        { name: 'sports', id: 4 },
        { name: 'off-road', id: 5 },
        { name: 'dual-purpose', id: 6 },
    ];
    const fuel = [
        { name: 'Petrol', id: 1 },
        { name: 'Diesel', id: 2 },
        { name: 'Ev', id: 3 },
    ];
    const selectFuelTypeChange = (e) => {
        const { value } = e.target;
        setFuelType(value);
    };
    const options = [
        { name: 'Yes', id: 1 },
        { name: 'No', id: 2 },
    ];

    const selectInsuranceStatusChange = (e) => {
        const { value } = e.target;
        setInsuranceStatus(value);
    };
    const handleCaseUpdate = async () => {
        const formData = {
            loanAgreementNo: loanAgmtNo,
            documentVerification: docVerified,
            regdOwner: regOwnerName,
            regdDate: regDate,
            manufacturingDate: mfgDate,
            numberPlate: numberPlateType,
            regdNo: regNumber,
            engNo: engineNo,
            chasisNo: chasisNo,
            bodyType: bodyType,
            fueltype: fuelType,
            vehicleColor: vehicleColor,
            vehicleWeight: grossWeight,
            ownerSerialNo: ownerSerial,
            hypothecationBank: hypothecationBank,
            insuranceCompany: insuranceCompany,
            insuranceStatus: insuranceStatus,
            cubicCapacity: cubicCapacity,
            seatingCapacity: seatingCapacity,
            extriorRemark: exteriorRemark,
        };
        console.log(formData, "formData");
        const config = {
            Headers: { 'Content-Type': 'application/json' },
        };
        const url = `${Baseurl}/api/v1/cases/updatecase/${params.id}`;
        const resp = await axios.put(url, formData, config);
        if (resp.data.success) {
            navigation(`/admin/editreport3/${params.id}`)
        }
    }


    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-2 space-y-6">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div className="flex items-center justify-between py-2 px-5"
                        style={{backgroundColor:'#4D44B5',color:'#fff',borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}
                        >
                            <h4 className="text-lg font-medium text-default-950 capitalize" style={{color:"#fff"}}>
                                Edit Case Details
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-3 gap-4">
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Loan Agreement No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Loan Agreement No"
                                            variant="outlined"
                                            className={ "textfield"}
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={loanAgmtNo}
                                            onChange={(e) => setLoanAgmtNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fDocVerified"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Document Verified *
                                    </label>
                                    <FormControl fullWidth style={{marginTop:'12px'}} className="textfield">
                                        <InputLabel id="demo-simple-Document-Verified">Doc Verified</InputLabel>
                                        <Select
                                            labelId="demo-simple-Document-Verified"
                                            id="demo-simple-select"
                                            value={docVerified}
                                            label="Doc Verified"
                                            onChange={(e) => selectDocuementVerifiedTypeChange(e)}
                                            
                                        >
                                            {documents.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fRegdOwner"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Registered Owner Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Regd Owner"
                                            variant="outlined"
                                            className={"textfield"}
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={regOwnerName}
                                            onChange={(e) => setRegOwnerName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fRegistrationDate"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Registration Date  <span style={{color:'red'}}>**</span>
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fRegistrationDate"
                                        placeholder="Enter Registration Date"
                                        value={regDate}
                                        onChange={(e) => {
                                            handleRegdDate(e)
                                        }}
                                        style={{marginTop:'12px'}}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fManufacturingDate"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Manufacturing Date <span style={{color:'red'}}>**</span>
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fManufacturingDate"
                                        placeholder="Enter Manufacturing Date"
                                        value={mfgDate}
                                        onChange={(e) => setMfgdate(e.target.value)}
                                        style={{marginTop:'12px'}}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fNumberPlate"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Number Plate Type *
                                    </label>
                                    <FormControl fullWidth style={{marginTop:'12px'}} className="textfield">
                                        <InputLabel id="demo-simple-Number-Plate-Type">Number Plate Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-Number-Plate-Type"
                                            id="demo-simple-select"
                                            value={numberPlateType}
                                            label="Number Plate Type"
                                            onChange={(e) => selectNumberPlateTypeChange(e)}
                                            
                                        >
                                            {number.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fRegNumber"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Registration Number *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Registration Number"
                                            variant="outlined"
                                            className={"textfield"}
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={regNumber}
                                            onChange={(e) => setRegNumber(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fEngineNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Engine No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Engine No"
                                            variant="outlined"
                                            className={
                                                "textfield"
                                            }
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={engineNo}
                                            onChange={(e) => setEngineNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fChasis"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Chasis No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Chasis No"
                                            variant="outlined"
                                            className={ "textfield"}
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={chasisNo}
                                            onChange={(e) => setChasisNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fBodyType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Body Type *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Body Type"
                                            variant="outlined"
                                            className={
                                                 "textfield"
                                            }
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={bodyType}
                                            onChange={(e) => setBodyType(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fFuelType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Fuel Type *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Body Type"
                                            variant="outlined"
                                            className={"textfield "}
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={fuelType}
                                            onChange={(e) => setFuelType(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fVehicleColor"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Color *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Color"
                                            variant="outlined"
                                            className={"textfield "}
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={vehicleColor}
                                            onChange={(e) => setVehicleColor(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fGrossWeight"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Gross Weight Of Vehicle *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Gross Weight Of Vehicle"
                                            variant="outlined"
                                            className={"textfield "}
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={grossWeight}
                                            onChange={(e) => setGrossWeight(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fOwnerSerial"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Owner Serial No*
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Owner Serial No"
                                            variant="outlined"
                                            className={"textfield "}
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={ownerSerial}
                                            onChange={(e) => setOwnerSerial(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fHypothecationBank"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Hypothecation Bank Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Hypothecation Bank Name"
                                            variant="outlined"
                                            className={"textfield "}
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={hypothecationBank}
                                            onChange={(e) => setHypothecationBank(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fInsuranceCompany"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Insurance Company Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Insurance Company"
                                            variant="outlined"
                                            className={
                                            "textfield "
                                            }
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={insuranceCompany}
                                            onChange={(e) => setInsuranceCompany(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fInsuranceStatus"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Insurance Expired *
                                    </label>
                                    <FormControl fullWidth style={{marginTop:'12px'}} className="textfield">
                                        <InputLabel id="demo-simple-Insurance-Expired">Insurance Expired</InputLabel>
                                        <Select
                                            labelId="demo-simple-Insurance-Expired"
                                            id="demo-simple-select"
                                            value={insuranceStatus}
                                            label="Insurance Status"
                                            onChange={(e) => selectInsuranceStatusChange(e)}
                                            
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fCubicCapacity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Cubic Capacity *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Cubic Capacity"
                                            variant="outlined"
                                            className={"textfield "}
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={cubicCapacity}
                                            onChange={(e) => setCubicCapacity(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fSeatingCapacity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Seating Capacity *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Seating Capacity"
                                            variant="outlined"
                                            className={
                                            "textfield "
                                            }
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={seatingCapacity}
                                            onChange={(e) => setSeatingCapacity(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fExteriorRemark"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Exterior Remark *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Exterior Remark"
                                            variant="outlined"
                                            className={
                                            "textfield "
                                            }
                                            style={{marginTop:'5px'}}
                                            type="text"
                                            id="text"
                                            value={exteriorRemark}
                                            onChange={(e) => setExteriorRemark(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center gap-2 justify-end" style={{ marginBottom: '80px' }}>
                                <button
                                    style={{ background: "#ef4444", color: '#fff', border: 'none', outline: 'none' }}
                                    className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
                                    onClick={() => navigation(`/admin/editreport/${params.id}`)}
                                >
                                    <i className="ph-bold ph-x text-lg" />Go Back
                                </button>
                                <button
                                    className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                                    style={{ background: "#4D44B5", border: 'none', outline: 'none' }}
                                    onClick={() => handleCaseUpdate()}
                                >
                                    <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditReport2