import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CiBellOn } from "react-icons/ci";
import { FaRegTrashCan } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Baseurl,Imageurl,  } from "../../config/BaseUrl";
import { updateNotification } from '../../redux/Notifications/NotificationSlice';

const Notification = () => {
    const { notifications } = useSelector((store) => store.notification);
    const navigate = useNavigate()
    const dispatch=useDispatch()
    const handleNotification = async (individualNotification) => {
        if (!individualNotification.viewed) {
            const formData = {
                viewed: true,
                _id:individualNotification._id
            };
            const resp=await dispatch(updateNotification(formData))
            if (resp.payload.success) {
                console.log(resp.payload,"hiii");
                if (resp.payload.notification.status === 2) {
                    navigate("/admin/pendingcases")
                } else if (resp.payload.notification.status === 3) {
                    navigate("/admin/submittedcases")
                }
            }

        }
    }

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Notifications
                        </h4>
                    </div>
                </div>

                <div className="grid grid-cols-1">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50" style={{ marginBottom: '80px' }}>
                        {notifications && notifications.map((individualNotification, index) =>
                            <div key={index}
                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '50px', padding: '10px', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px', margin: '10px', backgroundColor: individualNotification.viewed ? '#ddd' : '' }}
                                onClick={() => handleNotification(individualNotification)}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '30px' }}>
                                    <CiBellOn style={{ height: '30px', width: '25px' }} />
                                    {individualNotification.requestedBy === "Admin" ? (
                                        <p>A case request has been created by you</p>
                                    ) : (
                                        <p>{individualNotification.statusText}{individualNotification.requestedBy}</p>
                                    )}
                                </div>
                                <div>
                                    {/* <FaRegTrashCan style={{ height: '20px', width: '20px' }} /> */}
                                </div>
                            </div>
                        )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Notification