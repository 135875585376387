import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Baseurl,Imageurl,  } from "../../config/BaseUrl";
import { InputAdornment, TextField } from "@mui/material";

const ThreeWheelerEditReport4 = () => {
    const params = useParams()
    const navigation = useNavigate()
    const [assetType, setAssetType] = useState('');
    const [odometerType, setOdometerType] = useState('');
    const [fitnessExpiryDate, setFitnessExpiryDate] = useState('');
    const [meterReading, setMeterReading] = useState('');
    const [roadTaxValidityDate, setRoadTaxValidity] = useState('');
    const [engineCondition, setEngineCondition] = useState('');
    const [engineConditionError, setEngineConditionError] = useState('');
    const [fuelTankCondition, setFuelTankCondition] = useState('');
    const [engineShield, setEngineShield] = useState('');
    const [transmissionType, setTransmissionType] = useState('');
    const [transmissionTypeError, setTransmissionTypeError] = useState('');
    const [transmissionCondition, setTransmissionCondition] = useState('');
    const [transmissionConditionError, setTransmissionConditionError] = useState('');
    const [suspensionCondition, setSuspensionCondition] = useState('');
    const [suspensionConditionError, setSuspensionConditionError] = useState('');
    const [electricalCondition, setElectricalCondition] = useState('');
    const [electricalConditionError, setElectricalConditionError] = useState('');
    const [interiorCondition, setInteriorCondition] = useState('');
    const [InteriorConditionError, setInteriorConditionError] = useState('');
    const [exteriorCondition, setExteriorCondition] = useState('');
    const [exteriorConditionError, setExteriorConditionError] = useState('');
    const [frontBreakCondition, setFrontBreakCondition] = useState('');
    const [frontBreakConditionError, setFrontBreakConditionError] = useState('');
    const [rearBreakCondition, setRearBreakCondition] = useState('');
    const [rearBreakConditionError, setRearBreakConditionError] = useState('');
    const [handBrake, setHandBrake] = useState('');
    const [handBrakeError, setHandBrakeError] = useState('');
    const [wheelType, setWheelType] = useState('');
    const [wheelTypeError, setWheelTypeError] = useState('');
    const [vehicleFront, setVehicleFront] = useState('');
    const [vehicleFrontError, setVehicleFrontError] = useState('');
    const [vehicleLhRear, setVehicleLhRear] = useState('');
    const [vehicleLhRearError, setVehicleLhRearError] = useState('');
    const [vehicleRhRear, setVehicleRhRear] = useState('');
    const [vehicleRhRearError, setVehicleRhRearError] = useState('');
    const [vehicleSpare, setVehicleSpare] = useState('');
    const [vehicleSpareError, setVehicleSpareError] = useState('');
    const [totalTyre, setTotalTyre] = useState('');
    const [totalTyreError, setTotalTyreError] = useState('');
    const [availableTyre, setAvailableTyre] = useState('');
    const [availableTyreError, setAvailableTyreError] = useState('');
    const [missingTyre, setMissingTyre] = useState('');
    const [missingTyreError, setMissingTyreError] = useState('');
    const [spareTyre, setSpareTyre] = useState('');
    const [spareTyreError, setSpareTyreError] = useState('');
    const [vehicleSeat, setVehicleSeat] = useState('');
    const [vehicleSeatError, setVehicleSeatError] = useState('');
    const [vehicleInteriorTrims, setVehicleInteriorTrims] = useState('');
    const [vehicleInteriorTrimsError, setVehicleInteriorTrimsError] = useState('');
    const [vehicleInsideRearViewMirror, setVehicleInsideRearViewMirror] = useState('');
    const [vehicleInsideRearViewMirrorError, setVehicleInsideRearViewMirrorError] = useState('');
    const [vehicleBodyType, setVehicleBodyType] = useState('');
    const [vehicleBodyTypeError, setVehicleBodyTypeError] = useState('');
    const [vehicleFrontMudGuard, setVehicleFrontMudGuard] = useState('');
    const [vehicleFrontMudGuardError, setVehicleFrontMudGuardError] = useState('');
    const [vehicleRearWheelMudGuard, setVehicleRearWheelMudGuard] = useState('');
    const [vehicleRearWheelMudGuardError, setVehicleRearWheelMudGuardError] = useState('');
    const [vehicleWindScreen, setVehicleWindScreen] = useState('');
    const [vehicleWindScreenError, setVehicleWindScreenError] = useState('');
    const [vehicleWindScreenGlass, setVehicleWindScreenGlass] = useState('');
    const [vehicleWindScreenGlassError, setVehicleWindScreenGlassError] = useState('');
    const [vehicleSideDoor, setVehicleSideDoor] = useState('');
    const [vehicleSideDoorError, setVehicleSideDoorError] = useState('');
    const [vehicleDoorGlasses, setVehicleDoorGlasses] = useState('');
    const [vehicleDoorGlassesError, setVehicleDoorGlassesError] = useState('');
    const [vehicleOutsideRearViewMirror, setVehicleOutsideRearViewMirror] = useState('');
    const [vehicleOutsideRearViewMirrorError, setVehicleOutsideRearViewMirrorError] = useState('');
    const [vehiclePaint, setVehiclePaint] = useState('');
    const [vehiclePaintError, setVehiclePaintError] = useState('');
    const [vehiclePaintCondition, setVehiclePaintCondition] = useState('');
    const [vehiclePaintConditionError, setVehiclePaintConditionError] = useState('');
    const [vehicleOverAllBodyCondition, setVehicleOverAllBodyCondition] = useState('');
    const [vehicleOverAllBodyConditionError, setVehicleOverAllBodyConditionError] = useState('');
    const [vehicleBattery, setVehicleBattery] = useState('');
    const [vehicleBatteryError, setVehicleBatteryError] = useState('');
    const [vehicleHeadLamp, setVehicleHeadLamp] = useState('');
    const [vehicleHeadLampError, setVehicleHeadLampError] = useState('');
    const [vehicleFrontIndicators, setVehicleFrontIndicators] = useState('');
    const [vehicleFrontIndicatorsError, setVehicleFrontIndicatorsError] = useState('');
    const [vehicleRearIndicators, setVehicleRearIndicators] = useState('');
    const [vehicleRearIndicatorsError, setVehicleRearIndicatorsError] = useState('');
    const [vehicleTailLamp, setVehicleTailLamp] = useState('');
    const [vehicleTailLampError, setVehicleTailLampError] = useState('');
    const [vehicleToolkit, setVehicleToolkit] = useState('');
    const [vehicleToolkitError, setVehicleToolkitError] = useState('');
    const [vehicleOthersAccessories, setVehicleOthersAccessories] = useState('');
    const [vehicleOthersAccessoriesError, setVehicleOthersAccessoriesError] = useState('');
    const [accidental, setAccidental] = useState('');
    const [accidentalError, setAccidentalError] = useState('');
    const [otherRepair, setotherRepair] = useState('');
    const [otherRepairError, setotherRepairError] = useState('');
    const [maintenanceLevel, setMaintenanceLevel] = useState('');
    const [maintenanceLevelError, setMaintenanceLevelError] = useState('');

    const [yourRemark, setYourRemark] = useState('');
    const [exShowRoomPrice, setExShowRoomPrice] =
        useState('');
    const [exShowRoomPriceError, setExShowRoomPriceError] =
        useState('');
    const [estimatedPrice, setEstimatedPrice] =
        useState('');
    const [estimatedPriceError, setEstimatedPriceError] =
        useState('');
    const [depreciatedPrice, setDepreciatedPrice] = useState("");
    const [urlPath, setUrlPath] = useState('')
    const [imagePath, setImagePath] = useState("");
    const [pressBtn, setPressBtn] = useState(false)
    const [vehicleEdetails, setVehicleEdetails] = useState('')

    const typeList = [
        { id: 1, name: 'Manual' },
        { id: 2, name: 'Auto' },
    ];
    const odometer = [
        { id: 1, name: 'Digital' },
        { id: 2, name: 'Normal' },
    ];
    const selectOdometerTypeChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setOdometerType(text);
    };
    const list = [
        { name: '10', id: 1 },
        { name: '20', id: 2 },
        { name: '30', id: 3 },
        { name: '40', id: 4 },
        { name: '50', id: 5 },
        { name: '60', id: 6 },
        { name: '70', id: 7 },
        { name: '80', id: 8 },
        { name: '90', id: 9 },
        { name: '100', id: 10 },
    ];
    const wheelTypeList = [
        { name: 'Alloy', id: 1 },
        { name: 'Steel', value: 2 },
        { name: 'Rim', value: 3 },
    ];
    const selectWheelTypeChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setWheelType(text);
    };
    const reqCondition = [
        { name: 'Poor', id: 1 },
        { name: 'Average', id: 2 },
        { name: 'Satisfactory', id: 3 },
        { name: 'Good', id: 4 },
        { name: 'Excellent', id: 5 },
    ];
    const condition = [
        { name: 'Poor', id: 1 },
        { name: 'Average', id: 2 },
        { name: 'Satisfactory', id: 3 },
        { name: 'Good', id: 4 },
        { name: 'Excellent', id: 5 },
        { name: 'NA', id: 6 },
    ];
    const qualityList = [
        { name: 'Original', id: 1 },
        { name: 'Replaced', id: 2 },
    ];
    const bodyType = [
        { name: 'PASSENGER', id: 1 },
        { name: 'GOODS', id: 2 },
    ];
    const options = [
        { name: 'Yes', id: 1 },
        { name: 'No', id: 2 },
    ];
    const selectEngineConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setEngineCondition(text);
    };
    const selectFuelTankConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setFuelTankCondition(text);
    };
    const selectEngineSheildConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setEngineShield(text);
    };
    const selectTransmissionTypeChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setTransmissionType(text);
    };
    const selectTransmissionConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setTransmissionCondition(text);
    };
    const selectSuspensionConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setSuspensionCondition(text);
    };
    const selectElectricalConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setElectricalCondition(text);
    };
    const selectFrontBreakConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setFrontBreakCondition(text);
    };
    const selectRearBreakConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setRearBreakCondition(text);
    };
    const selectHandBreakConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setHandBrake(text);
    };
    const selectFrontTyreConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleFront(text);
    };
    const selectVehicleLhRearConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleLhRear(text);
    };
    const selectVehicleRhRearConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleRhRear(text);
    };
    const selectVehicleSpareTyreConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleSpare(text);
    };
    const selectVehicleSeatConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleSeat(text);
    };
    const selectVehicleInteriorTrimsConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleInteriorTrims(text);
    };
    const selectVehicleInsideRearViewMirrorChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleInsideRearViewMirror(text);
    };
    const selectVehicleBodyTypeChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleBodyType(text);
    };
    const selectVehicleFrontMudGuardCondtionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleFrontMudGuard(text);
    };
    const selectVehicleRearWheelMudGuardCondtionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleRearWheelMudGuard(text);
    };
    const selectVehicleWindScreenCondtionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleWindScreen(text);
    };
    const selectVehicleWindScreenGlassChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleWindScreenGlass(text);
    };
    const selectVehicleSideDoorChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleSideDoor(text);
    };
    const selectVehicleDoorGlassesConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleDoorGlasses(text);
    };
    const selectVehicleOutsideRearViewMirrorChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleOutsideRearViewMirror(text);
    };
    const selectPaintChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehiclePaint(text);
    };
    const selectPaintConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehiclePaintCondition(text);
    };
    const selectOverAllBodyConditionChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleOverAllBodyCondition(text);
    };
    const selectVehicleBatteryChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleBattery(text);
    };
    const SelectVehicleHeadLampChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleHeadLamp(text);
    };
    const SelectFrontIndicatorsChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleFrontIndicators(text);
    };
    const SelectRearIndicatorsChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleRearIndicators(text);
    };
    const SelectTailLampChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleTailLamp(text);
    };
    const SelectVehicleToolkitChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleToolkit(text);
    };
    const SelectVehicleOthersAccessoriesChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setVehicleOthersAccessories(text);
    };
    const SelectAccidentalStatus = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setAccidental(text);
    };
    const SelectotherRepairStatus = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setotherRepair(text);
    };
    const SelectMaintenanceLevelCondition = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setMaintenanceLevel(text);
    };
    useEffect(() => {
        if (pressBtn) {
            if (engineCondition === '') {
                setEngineConditionError('Please enter engine condition')
            } else {
                setEngineConditionError('')
            }
            if (transmissionCondition === '') {
                setTransmissionConditionError('Please enter engine condition')
            } else {
                setTransmissionConditionError('')
            }
            if (suspensionCondition === '') {
                setSuspensionConditionError('Please enter engine condition')
            } else {
                setSuspensionConditionError('')
            }
            if (frontBreakCondition === '') {
                setFrontBreakConditionError('Please enter engine condition')
            } else {
                setFrontBreakConditionError('')
            }
            if (rearBreakCondition === '') {
                setRearBreakConditionError('Please enter engine condition')
            } else {
                setRearBreakConditionError('')
            }
            if (vehicleFront === '') {
                setVehicleFrontError('Please enter engine condition')
            } else {
                setVehicleFrontError('')
            }
            if (vehicleLhRear === '') {
                setVehicleLhRearError('Please enter engine condition')
            } else {
                setVehicleLhRearError('')
            }
            if (vehicleRhRear === '') {
                setVehicleRhRearError('Please enter engine condition')
            } else {
                setVehicleRhRearError('')
            }
            if (vehicleSpare === '') {
                setVehicleSpareError('Please enter engine condition')
            } else {
                setVehicleSpareError('')
            }
            if (vehicleOverAllBodyCondition === '') {
                setVehicleOverAllBodyConditionError('Please enter engine condition')
            } else {
                setVehicleOverAllBodyConditionError('')
            }
            if (electricalCondition === '') {
                setElectricalConditionError('Please enter engine condition')
            } else {
                setElectricalConditionError('')
            }
            if (exShowRoomPrice === '') {
                setExShowRoomPriceError('Please enter Exshowroom Price')
            } else {
                setExShowRoomPriceError('')
            }
            if (estimatedPrice === '') {
                setEstimatedPriceError('Please enter Estimated Price')
            } else {
                setEstimatedPriceError('')
            }
        }
    }, [pressBtn, engineCondition, transmissionCondition, suspensionCondition, frontBreakCondition, rearBreakCondition, vehicleFront, vehicleLhRear, vehicleRhRear, vehicleSpare, vehicleOverAllBodyCondition, electricalCondition, exShowRoomPrice, estimatedPrice])
    const selectCaseType = [
        {
            id: 1,
            name: "Poor",
        },
        {
            id: 2,
            name: "Average",
        },
        {
            id: 3,
            name: "Satisfactory",
        },
        {
            id: 4,
            name: "Good",
        },
        {
            id: 5,
            name: "Excellent",
        },
    ];
    const selectCaseChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setYourRemark(text);
    };

    useEffect(() => {
        const fetchData = async () => {
            const url = `${Baseurl}/api/v1/cases/individualcase/${params.id}`;
            const resp = await axios.get(url);
            if (resp.data.success) {
                const data = resp.data.case;
                console.log(data, "data");
                setVehicleEdetails(data.vehicleEdetails)
                setAssetType(data.assetType)
                setOdometerType(data.threeWheeler.odometerType)
                setMeterReading(data?.meterReading);
                setFitnessExpiryDate(data.threeWheeler.fitnessExpiry)
                setRoadTaxValidity(data.threeWheeler.roadTaxValidity)
                setEngineCondition(data.threeWheeler.engineCondition)
                setFuelTankCondition(data.threeWheeler.fuelTankConditiuon)
                setEngineShield(data.threeWheeler.engineShield)
                setTransmissionType(data.threeWheeler.transmissionType)
                setTransmissionCondition(data.threeWheeler.transmissionCondition)
                setSuspensionCondition(data.threeWheeler.suspensionCondition)
                setElectricalCondition(data.threeWheeler.electricalCondition)
                setFrontBreakCondition(data.threeWheeler.frontBrakeCondition)
                setRearBreakCondition(data.threeWheeler.rearBrakeCondition)
                setHandBrake(data.threeWheeler.handBrake)
                setWheelType(data.threeWheeler.wheelType)
                setVehicleFront(data.threeWheeler.front)
                setVehicleLhRear(data.threeWheeler.lhRear)
                setVehicleRhRear(data.threeWheeler.rhRear)
                setVehicleSpare(data.threeWheeler.spare)
                setTotalTyre(data.threeWheeler.totalTyres)
                setAvailableTyre(data.threeWheeler.availableTyre)
                setMissingTyre(data.threeWheeler.missingTyre)
                setSpareTyre(data.threeWheeler.spareTyre)
                setVehicleSeat(data.threeWheeler.seats)
                setVehicleInteriorTrims(data.threeWheeler.interiorTrims)
                setVehicleInsideRearViewMirror(data.threeWheeler.insideRearViewMirror)
                setVehicleBodyType(data.threeWheeler.vehiclebodyType)
                setVehicleFrontMudGuard(data.threeWheeler.frontMudguard)
                setVehicleRearWheelMudGuard(data.threeWheeler.rearWheelsMudguards)
                setVehicleWindScreen(data.threeWheeler.windScreenCondition)
                setVehicleWindScreenGlass(data.threeWheeler.windScreenGlassStatus)
                setVehicleSideDoor(data.threeWheeler.sideDoors)
                setVehicleDoorGlasses(data.threeWheeler.doorGlasses)
                setVehicleOutsideRearViewMirror(data.threeWheeler.outsideRearViewMirror)
                setVehiclePaint(data.threeWheeler.paintStatus)
                setVehiclePaintCondition(data.threeWheeler.paintCondition)
                setVehicleOverAllBodyCondition(data.threeWheeler.overallBodyCondition)
                setVehicleBattery(data.threeWheeler.battery)
                setVehicleHeadLamp(data.threeWheeler.headLamp)
                setVehicleFrontIndicators(data.threeWheeler.frontIndicators)
                setVehicleRearIndicators(data.threeWheeler.rearIndicators)
                setVehicleTailLamp(data.threeWheeler.tailLamp)
                setVehicleToolkit(data.threeWheeler.toolKit)
                setVehicleOthersAccessories(data.threeWheeler.othersAccessories)
                setAccidental(data.threeWheeler.accidentalStatus)
                setotherRepair(data.threeWheeler.otherRepairs)
                setMaintenanceLevel(data.threeWheeler.maintenanceLevel)
                setYourRemark(data.yourRemark)
                setEstimatedPrice(data.valuation);
                setExShowRoomPrice(data.marketPrice);


                let path = ''
                if (data.assetType === 'Two Wheeler') {
                    path = `https://instavaluer.com/twowheelerreportrelease/${params.id}`
                } else if (data.assetType === 'Four Wheeler') {
                    path = `https://instavaluer.com/releasereport/${params.id}`
                } else if (data.assetType === 'Commercial') {
                    path = `https://instavaluer.com/commercialreportrelease/${params.id}`
                } else if (data.assetType === 'Construction Equipment') {
                    path = `https://instavaluer.com/constructionreportrelease/${params.id}`
                } else if (data.assetType === 'Three Wheeler') {
                    path = `https://instavaluer.com/threewheelerreportrelease/${params._id}`
                } else if (data.assetType === 'Farm Equipment') {
                    path = `https://instavaluer.com/firmequipmentreportrelease/${params._id}`
                }
                setUrlPath(path)
                const imagePath = `https://instavaluer.com/imagereport/${params.id}`
                setImagePath(imagePath)
            }
        }
        fetchData()
    }, [params.id])

    const handleSubmit = async () => {
        if (engineCondition === '') {
            setEngineConditionError('Please enter engine condition')
        } else {
            setEngineConditionError('')
        }
        if (transmissionCondition === '') {
            setTransmissionConditionError('Please enter engine condition')
        } else {
            setTransmissionConditionError('')
        }
        if (suspensionCondition === '') {
            setSuspensionConditionError('Please enter engine condition')
        } else {
            setSuspensionConditionError('')
        }
        if (frontBreakCondition === '') {
            setFrontBreakConditionError('Please enter engine condition')
        } else {
            setFrontBreakConditionError('')
        }
        if (rearBreakCondition === '') {
            setRearBreakConditionError('Please enter engine condition')
        } else {
            setRearBreakConditionError('')
        }
        if (vehicleFront === '') {
            setVehicleFrontError('Please enter engine condition')
        } else {
            setVehicleFrontError('')
        }
        if (vehicleLhRear === '') {
            setVehicleLhRearError('Please enter engine condition')
        } else {
            setVehicleLhRearError('')
        }
        if (vehicleRhRear === '') {
            setVehicleRhRearError('Please enter engine condition')
        } else {
            setVehicleRhRearError('')
        }
        if (vehicleSpare === '') {
            setVehicleSpareError('Please enter engine condition')
        } else {
            setVehicleSpareError('')
        }
        if (vehicleOverAllBodyCondition === '') {
            setVehicleOverAllBodyConditionError('Please enter engine condition')
        } else {
            setVehicleOverAllBodyConditionError('')
        }
        if (electricalCondition === '') {
            setElectricalConditionError('Please enter engine condition')
        } else {
            setElectricalConditionError('')
        }
        if (exShowRoomPrice === '') {
            setExShowRoomPriceError('Please enter Exshowroom Price')
        } else {
            setExShowRoomPriceError('')
        }
        if (estimatedPrice === '') {
            setEstimatedPriceError('Please enter Estimated Price')
        } else {
            setEstimatedPriceError('')
        }
        if (engineCondition !== '' && transmissionCondition !== '' && suspensionCondition !== '' && frontBreakCondition !== '' && rearBreakCondition !== '' && vehicleFront !== '' && vehicleLhRear !== '' && vehicleRhRear !== '' && vehicleSpare !== '' && vehicleOverAllBodyCondition !== '' && electricalCondition !== '' && exShowRoomPrice !== '' && estimatedPrice !== '') {

            const currentDate = new Date();
            const todayDate = currentDate.toISOString().slice(0, 10)

            const formData = {
                meterReading: meterReading,
                threeWheeler: {
                    odometerType: odometerType,
                    fitnessExpiry: fitnessExpiryDate,
                    roadTaxValidity: roadTaxValidityDate,
                    engineCondition: engineCondition,
                    fuelTankConditiuon: fuelTankCondition,
                    engineShield: engineShield,
                    transmissionType: transmissionType,
                    transmissionCondition: transmissionCondition,
                    suspensionCondition: suspensionCondition,
                    frontBrakeCondition: frontBreakCondition,
                    rearBrakeCondition: rearBreakCondition,
                    handBrake: handBrake,
                    wheelType: wheelType,
                    front: vehicleFront,
                    lhRear: vehicleLhRear,
                    rhRear: vehicleRhRear,
                    spare: vehicleSpare,
                    totalTyres: totalTyre,
                    availableTyre: availableTyre,
                    missingTyre: missingTyre,
                    spareTyre: spareTyre,
                    seats: vehicleSeat,
                    interiorTrims: vehicleInteriorTrims,
                    insideRearViewMirror: vehicleInsideRearViewMirror,
                    vehiclebodyType: vehicleBodyType,
                    frontMudguard: vehicleFrontMudGuard,
                    rearWheelsMudguards: vehicleRearWheelMudGuard,
                    windScreenCondition: vehicleWindScreen,
                    windScreenGlassStatus: vehicleWindScreenGlass,
                    sideDoors: vehicleSideDoor,
                    doorGlasses: vehicleDoorGlasses,
                    outsideRearViewMirror: vehicleOutsideRearViewMirror,
                    paintStatus: vehiclePaint,
                    paintCondition: vehiclePaintCondition,
                    overallBodyCondition: vehicleOverAllBodyCondition,
                    battery: vehicleBattery,
                    headLamp: vehicleHeadLamp,
                    frontIndicators: vehicleFrontIndicators,
                    rearIndicators: vehicleRearIndicators,
                    tailLamp: vehicleTailLamp,
                    electricalCondition: electricalCondition,
                    toolKit: vehicleToolkit,
                    othersAccessories: vehicleOthersAccessories,
                    accidentalStatus: accidental,
                    maintenanceLevel: maintenanceLevel,
                    otherRepairs: otherRepair
                },
                yourRemark: yourRemark,
                valuation: estimatedPrice,
                marketPrice: exShowRoomPrice,
                reportUrl: urlPath,
                imageUrl: imagePath,
                submittedAt: todayDate,
                status: 5,
                statusText: 'Case Url Updated',
                currentForm: 4,
            };
            const config = {
                Headers: { 'Content-Type': 'application/json' },
            };
            const url = `${Baseurl}/api/v1/cases/updatecase/${params.id}`;
            const resp = await axios.put(url, formData, config);
            if (resp.data.success) {
                navigation(`/threewheelerreportrelease/${params.id}`)
            }
        } else {
            setPressBtn(true)
        }
    };

    useEffect(() => {
        if (transmissionCondition !== '' &&
            engineCondition !== '' &&
            electricalCondition !== '' &&
            vehicleOverAllBodyCondition !== '' &&
            suspensionCondition !== '' &&
            frontBreakCondition !== '' &&
            rearBreakCondition !== '' &&
            vehicleLhRear !== '' &&
            vehicleRhRear !== '' &&
            vehicleFront !== '' &&
            vehicleSpare !== '' &&
            exShowRoomPrice !== ''
        ) {

            const roundImageArray = [
                {
                    label: "Poor",
                    percentage: 2,
                    roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/leg02.png",
                },
                {
                    label: "Average",
                    percentage: 4,
                    roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/leg04.png",
                },
                {
                    label: "Satisfactory",
                    percentage: 5,
                    roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/leg06.png",
                },
                {
                    label: "Good",
                    percentage: 7,
                    roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png",
                },
                {
                    label: "Excellent",
                    percentage: 9,
                    roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/leg09.png",
                },
            ];

            let findEngineCon = "";
            let findTransmissionCon = "";
            let findSuspensionCon = "";
            let findBrakesCon = "";
            let findTyreCon = "";
            let findElectricalCon = "";
            let findExteriorCon = "";
            let finalBreakCondition = "";
            let finalTyreCondition = "";

            findEngineCon = roundImageArray.find(
                (data) =>
                    data.label === engineCondition
            );

            findTransmissionCon = roundImageArray.find(
                (data) => data.label === transmissionCondition
            );
            findElectricalCon = roundImageArray.find(
                (data) =>
                    data.label === electricalCondition
            );
            findExteriorCon = roundImageArray.find(
                (data) =>
                    data.label === vehicleOverAllBodyCondition
            );
            findSuspensionCon = roundImageArray.find(
                (data) =>
                    data.label === suspensionCondition
            );
            const findRareBrakesCon = roundImageArray.find(
                (data) =>
                    data.label === frontBreakCondition
            );
            const findFrontBrakesCon = roundImageArray.find(
                (data) =>
                    data.label === rearBreakCondition
            );


            const TotalTyreCon =
                Number(vehicleLhRear) +
                Number(vehicleRhRear) +
                Number(vehicleFront) +
                Number(vehicleSpare)

            findTyreCon = Number(TotalTyreCon) / 30;
            findTyreCon = Math.round(findTyreCon);

            if (findTyreCon === 1 || findTyreCon === 2) {
                finalTyreCondition = 2;
            } else if (findTyreCon === 3 || findTyreCon === 4) {
                finalTyreCondition = 4;
            } else if (findTyreCon === 5 || findTyreCon === 6) {
                finalTyreCondition = 5;
            } else if (findTyreCon === 7 || findTyreCon === 8) {
                finalTyreCondition = 7;
            } else if (findTyreCon === 9 || findTyreCon === 10) {
                finalTyreCondition = 9;
            }

            const totalBrackcon =
                Number(findFrontBrakesCon.percentage) +
                Number(findRareBrakesCon.percentage);
            findBrakesCon = Number(totalBrackcon) / 2;
            findBrakesCon = Math.round(findBrakesCon);

            if (findBrakesCon === 1 || findBrakesCon === 2) {
                finalBreakCondition = 2;
            } else if (findBrakesCon === 3 || findBrakesCon === 4) {
                finalBreakCondition = 4;
            } else if (findBrakesCon === 5 || findBrakesCon === 6) {
                finalBreakCondition = 5;
            } else if (findBrakesCon === 7 || findBrakesCon === 8) {
                finalBreakCondition = 7;
            } else if (findBrakesCon === 9 || findBrakesCon === 10) {
                finalBreakCondition = 9;
            }


            const findBreakCondition = roundImageArray.find(
                (data) => data.percentage === finalBreakCondition
            );
            const findTyreCondition = roundImageArray.find(
                (data) => data.percentage === finalTyreCondition
            );
            const overalRating =
                Number(findEngineCon.percentage) * Number(3) +
                Number(findTransmissionCon.percentage) * Number(1) +
                Number(findSuspensionCon.percentage) * Number(1) +
                Number(findElectricalCon.percentage) * Number(1) +
                Number(findExteriorCon.percentage) * Number(1) +
                Number(finalBreakCondition) * Number(1) +
                Number(finalTyreCondition) * Number(1);


            const overalavg = Number(overalRating) / 10;
            console.log(overalavg, 'overalavg');
            let deprePerYear = {};

            if (overalavg >= 8) {
                deprePerYear = { 1: 7, 2: 14, 3: 18, 4: 20, 5: 25, 6: 28, 7: 32, 8: 34, 9: 36, 10: 38, 11: 48 };
            } else if (overalavg >= 7 && overalavg < 8) {
                deprePerYear = { 1: 10, 2: 16, 3: 22, 4: 25, 5: 28, 6: 30, 7: 35, 8: 38, 9: 38, 10: 40, 11: 48 };
            } else if (overalavg >= 6 && overalavg < 7) {
                deprePerYear = { 1: 15, 2: 20, 3: 25, 4: 30, 5: 35, 6: 40, 7: 42, 8: 42, 9: 44, 10: 46, 11: 48 };
            } else if (overalavg >= 5 && overalavg < 6) {
                deprePerYear = { 1: 25, 2: 35, 3: 45, 4: 47, 5: 49, 6: 52, 7: 53, 8: 55, 9: 55, 10: 55, 11: 55 };
            }

            const mfg = vehicleEdetails.vehicleManufacturingMonthYear.slice(-4);
            const currentYear = new Date().getFullYear();
            const depreYear = currentYear - parseInt(mfg, 10);

            // Check if depreYear exists in deprePerYear and calculate depreciation if true

            if (depreYear >= 11) {
                const percentageValue = (48 / 100) * exShowRoomPrice;
                const depreciationValue = exShowRoomPrice - percentageValue;
                setEstimatedPrice(Math.floor(depreciationValue));
                setDepreciatedPrice(Math.floor(depreciationValue));
            } else if (deprePerYear.hasOwnProperty(depreYear)) {
                const percentageValue = (deprePerYear[depreYear] / 100) * exShowRoomPrice;
                const depreciationValue = exShowRoomPrice - percentageValue;
                setEstimatedPrice(Math.floor(depreciationValue));
                setDepreciatedPrice(Math.floor(depreciationValue));
            }

        }
    }, [transmissionCondition, engineCondition,
        electricalCondition, vehicleOverAllBodyCondition,
        suspensionCondition, frontBreakCondition,
        rearBreakCondition, vehicleLhRear,
        vehicleRhRear, vehicleFront, vehicleSpare, exShowRoomPrice
    ])

    return (
        <div className="min-h-screen flex flex-col lg:ps-64 w-full">
            <div className="p-2 space-y-6">
                <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                    <div className="flex items-center justify-between py-2 px-5"
                        style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                    >
                        <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
                            Edit Case Details
                        </h4>
                    </div>
                    <div className="p-5 border-t border-dashed border-default-200">
                        <div className="grid md:grid-cols-3 gap-4">
                            <div className="space-y-1">
                                <label
                                    htmlFor="fOdometerType"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Odometer Type *
                                </label>
                                <select
                                    className={
                                        `border-default-200 w-full`
                                    }
                                    data-trigger
                                    id="fOdometerType"
                                    placeholder="This is a search placeholder"
                                    value={odometer.id}
                                    name={odometer.name}
                                    onChange={(e) => selectOdometerTypeChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Odometer Type
                                    </option>
                                    {odometer.map((test, index) => (
                                        <option
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fMeterReading"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Meter Reading *
                                </label>
                                <div className="textfieldcontainer">
                                    <TextField
                                        label="Meter Reading"
                                        variant="outlined"
                                        className={
                                            "textfield"
                                        }
                                        style={{ marginTop: '5px' }}
                                        type="text"
                                        id="text"
                                        value={meterReading}
                                        onChange={(e) => setMeterReading(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fFitnessExpiryDate"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Fitness Expiry Date *
                                </label>
                                <input
                                    type="date"
                                    className={
                                        "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                    }
                                    id="fFitnessExpiryDate"
                                    placeholder="Enter Fitness Expiry Date"
                                    value={fitnessExpiryDate}
                                    onChange={(e) => setFitnessExpiryDate(e.target.value)}
                                />
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fRoadTaxValidityDate"
                                    className="text-sm font-medium text-default-900"
                                >
                                    RoadTax Validity Date *
                                </label>
                                <input
                                    type="date"
                                    className={
                                        "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                    }
                                    id="fRoadTaxValidityDate"
                                    placeholder="Enter Fitness Expiry Date"
                                    value={roadTaxValidityDate}
                                    onChange={(e) => setRoadTaxValidity(e.target.value)}
                                />
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fEngineCondition"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Engine Condition <span style={{ color: 'red' }}>**</span>
                                </label>
                                <select
                                    className={
                                        engineConditionError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fEngineCondition"
                                    placeholder="This is a search placeholder"
                                    value={reqCondition.id}
                                    name={reqCondition.name}
                                    onChange={(e) => selectEngineConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Engine Condition
                                    </option>
                                    {reqCondition.map((test, index) => (
                                        <option
                                            selected={test.name === engineCondition}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fFuelTankCondition"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Fuel Tank Condition *
                                </label>
                                <select
                                    className={
                                        `border-default-200 w-full`
                                    }
                                    data-trigger
                                    id="fFuelTankCondition"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectFuelTankConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Fuel Tank Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === fuelTankCondition}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fEngineSheildCondition"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Engine Sheild Condition *
                                </label>
                                <select
                                    className={
                                        `border-default-200 w-full`
                                    }
                                    data-trigger
                                    id="fEngineSheildCondition"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectEngineSheildConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Engine Sheild Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === engineShield}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fTransmissionType"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Transmission Type *
                                </label>
                                <select
                                    className={
                                        transmissionTypeError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fTransmissionType"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectTransmissionTypeChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Transmission Type
                                    </option>
                                    {typeList.map((test, index) => (
                                        <option
                                            selected={test.name === transmissionType}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fTransmissionCondition"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Transmission Condition <span style={{ color: 'red' }}>**</span>
                                </label>
                                <select
                                    className={
                                        transmissionConditionError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fTransmissionCondition"
                                    placeholder="This is a search placeholder"
                                    value={reqCondition.id}
                                    name={reqCondition.name}
                                    onChange={(e) => selectTransmissionConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Transmission Condition
                                    </option>
                                    {reqCondition.map((test, index) => (
                                        <option
                                            selected={test.name === transmissionCondition}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fSuspensionCondition"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Suspension Condition <span style={{ color: 'red' }}>**</span>
                                </label>
                                <select
                                    className={
                                        suspensionConditionError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fSuspensionCondition"
                                    placeholder="This is a search placeholder"
                                    value={reqCondition.id}
                                    name={reqCondition.name}
                                    onChange={(e) => selectSuspensionConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Suspension Condition
                                    </option>
                                    {reqCondition.map((test, index) => (
                                        <option
                                            selected={test.name === suspensionCondition}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fFrontBreakCondition"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Front Break Condition <span style={{ color: 'red' }}>**</span>
                                </label>
                                <select
                                    className={
                                        frontBreakConditionError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fFrontBreakCondition"
                                    placeholder="This is a search placeholder"
                                    value={reqCondition.id}
                                    name={reqCondition.name}
                                    onChange={(e) => selectFrontBreakConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose FrontBreak Condition
                                    </option>
                                    {reqCondition.map((test, index) => (
                                        <option
                                            selected={test.name === frontBreakCondition}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fRearBreakCondition"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Rear Break Condition <span style={{ color: 'red' }}>**</span>
                                </label>
                                <select
                                    className={
                                        rearBreakConditionError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fRearBreakCondition"
                                    placeholder="This is a search placeholder"
                                    value={reqCondition.id}
                                    name={reqCondition.name}
                                    onChange={(e) => selectRearBreakConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose RearBreak Condition
                                    </option>
                                    {reqCondition.map((test, index) => (
                                        <option
                                            selected={test.name === rearBreakCondition}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fHandBreakCondition"
                                    className="text-sm font-medium text-default-900"
                                >
                                    HandBreak Condition *
                                </label>
                                <select
                                    className={
                                        handBrakeError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fHandBreakCondition"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectHandBreakConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose HandBreak Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === handBrake}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fWheelType"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Wheel Type *
                                </label>
                                <select
                                    className={
                                        wheelTypeError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fWheelType"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectWheelTypeChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Wheel Type
                                    </option>
                                    {wheelTypeList.map((test, index) => (
                                        <option
                                            selected={test.name === wheelType}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fFrontTyre"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Front Tyre Condition <span style={{ color: 'red' }}>**</span>
                                </label>
                                <select
                                    className={
                                        vehicleFrontError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fFrontTyre"
                                    placeholder="This is a search placeholder"
                                    value={list.id}
                                    name={list.name}
                                    onChange={(e) => selectFrontTyreConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Front Tyre Condition
                                    </option>
                                    {list.map((test, index) => (
                                        <option
                                            selected={Number(test.name) === vehicleFront}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fLhRear"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Lh Rear Condition <span style={{ color: 'red' }}>**</span>
                                </label>
                                <select
                                    className={
                                        vehicleLhRearError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fLhRear"
                                    placeholder="This is a search placeholder"
                                    value={list.id}
                                    name={list.name}
                                    onChange={(e) => selectVehicleLhRearConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Lh Rear Condition
                                    </option>
                                    {list.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleLhRear}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fRhRear"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Rh Rear Condition <span style={{ color: 'red' }}>**</span>
                                </label>
                                <select
                                    className={
                                        vehicleRhRearError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fRhRear"
                                    placeholder="This is a search placeholder"
                                    value={list.id}
                                    name={list.name}
                                    onChange={(e) => selectVehicleRhRearConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Rh Rear Condition
                                    </option>
                                    {list.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleRhRear}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fSpareTyre"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Spare Tyre Condition <span style={{ color: 'red' }}>**</span>
                                </label>
                                <select
                                    className={
                                        vehicleSpareError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fSpareTyre"
                                    placeholder="This is a search placeholder"
                                    value={list.id}
                                    name={list.name}
                                    onChange={(e) => selectVehicleSpareTyreConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Spare Tyre Condition
                                    </option>
                                    {list.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleSpare}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fTotalTyre"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Total Tyre *
                                </label>
                                <input
                                    type="text"
                                    className={
                                        "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                    }
                                    id="fTotalTyre"
                                    placeholder="Enter Total Tyre"
                                    value={totalTyre}
                                    onChange={(e) => setTotalTyre(e.target.value)}
                                />
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fAvailableTyre"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Available Tyre *
                                </label>
                                <input
                                    type="text"
                                    className={
                                        "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                    }
                                    id="fAvailableTyre"
                                    placeholder="Enter Available Tyre"
                                    value={availableTyre}
                                    onChange={(e) => setAvailableTyre(e.target.value)}
                                />
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fMissingTyre"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Missing Tyre *
                                </label>
                                <input
                                    type="text"
                                    className={
                                        "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                    }
                                    id="fMissingTyre"
                                    placeholder="Enter Missing Tyre"
                                    value={missingTyre}
                                    onChange={(e) => setMissingTyre(e.target.value)}
                                />
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fSpareTyre"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Spare Tyre *
                                </label>
                                <input
                                    type="text"
                                    className={
                                        "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                    }
                                    id="fSpareTyre"
                                    placeholder="Enter Spare Tyre"
                                    value={spareTyre}
                                    onChange={(e) => setSpareTyre(e.target.value)}
                                />
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fSeatCondition"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Seat Condition *
                                </label>
                                <select
                                    className={
                                        vehicleSeatError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fSeatCondition"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectVehicleSeatConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Seat Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleSeat}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fInteriorTrims"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Interior Trims Condition *
                                </label>
                                <select
                                    className={
                                        vehicleInteriorTrimsError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fInteriorTrims"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectVehicleInteriorTrimsConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Interior Trims Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleInteriorTrims}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fInsideRearView"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Inside Rear View Mirror Available *
                                </label>
                                <select
                                    className={
                                        vehicleInsideRearViewMirrorError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fInsideRearView"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectVehicleInsideRearViewMirrorChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Inside Rear View Mirror Status
                                    </option>
                                    {options.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleInsideRearViewMirror}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fBodyType"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Body Type *
                                </label>
                                <select
                                    className={
                                        vehicleBodyTypeError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fBodyType"
                                    placeholder="This is a search placeholder"
                                    value={bodyType.id}
                                    name={bodyType.name}
                                    onChange={(e) => selectVehicleBodyTypeChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Body Type
                                    </option>
                                    {bodyType.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleBodyType}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fFrontMudGuard"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Front MudGuard Condition *
                                </label>
                                <select
                                    className={
                                        vehicleFrontMudGuardError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fFrontMudGuard"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectVehicleFrontMudGuardCondtionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Front MudGuard Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleFrontMudGuard}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fRearMudGuard"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Rear Wheel MudGuard Condition *
                                </label>
                                <select
                                    className={
                                        vehicleRearWheelMudGuardError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fRearMudGuard"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectVehicleRearWheelMudGuardCondtionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Rear Wheel MudGuard Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleRearWheelMudGuard}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fWindScreen"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Wind Screen Condition *
                                </label>
                                <select
                                    className={
                                        vehicleWindScreenError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fWindScreen"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectVehicleWindScreenCondtionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Wind Screen Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleWindScreen}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fWindScreenGlass"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Wind Screen Glass Status *
                                </label>
                                <select
                                    className={
                                        vehicleWindScreenGlassError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fWindScreenGlass"
                                    placeholder="This is a search placeholder"
                                    value={qualityList.id}
                                    name={qualityList.name}
                                    onChange={(e) => selectVehicleWindScreenGlassChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Wind Screen Glass Status
                                    </option>
                                    {qualityList.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleWindScreenGlass}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fSideDoor"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Side Door Condition *
                                </label>
                                <select
                                    className={
                                        vehicleSideDoorError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fSideDoor"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectVehicleSideDoorChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Side Door Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleSideDoor}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fDoorGlasses"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Door Glasses Condition *
                                </label>
                                <select
                                    className={
                                        vehicleDoorGlassesError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fDoorGlasses"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectVehicleDoorGlassesConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Door Glasses Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleDoorGlasses}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fDoorGlasses"
                                    className="text-sm font-medium text-default-900"
                                >
                                    OutSide Rear View Mirrors Condition *
                                </label>
                                <select
                                    className={
                                        vehicleOutsideRearViewMirrorError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fDoorGlasses"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectVehicleOutsideRearViewMirrorChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose OutSide Rear View Mirrors Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleOutsideRearViewMirror}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fPaint"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Paint Quality*
                                </label>
                                <select
                                    className={
                                        vehiclePaintError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fPaint"
                                    placeholder="This is a search placeholder"
                                    value={qualityList.id}
                                    name={qualityList.name}
                                    onChange={(e) => selectPaintChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Paint Quality
                                    </option>
                                    {qualityList.map((test, index) => (
                                        <option
                                            selected={test.name === vehiclePaint}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fPaintCondition"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Paint Condition *
                                </label>
                                <select
                                    className={
                                        vehiclePaintConditionError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fPaintCondition"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectPaintConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Paint Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehiclePaintCondition}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fOverAll"
                                    className="text-sm font-medium text-default-900"
                                >
                                    OverAll Body Condition <span style={{ color: 'red' }}>**</span>
                                </label>
                                <select
                                    className={
                                        vehicleOverAllBodyConditionError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fOverAll"
                                    placeholder="This is a search placeholder"
                                    value={reqCondition.id}
                                    name={reqCondition.name}
                                    onChange={(e) => selectOverAllBodyConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose OverAll Body Condition
                                    </option>
                                    {reqCondition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleOverAllBodyCondition}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fBattery"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Battery Condition *
                                </label>
                                <select
                                    className={
                                        vehicleBatteryError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fBattery"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => selectVehicleBatteryChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Battery Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleBattery}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fBattery"
                                    className="text-sm font-medium text-default-900"
                                >
                                    HeadLamp Condition *
                                </label>
                                <select
                                    className={
                                        vehicleHeadLampError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fBattery"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => SelectVehicleHeadLampChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose HeadLamp Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleHeadLamp}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fFrontIndicator"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Front Indicator Condition *
                                </label>
                                <select
                                    className={
                                        vehicleFrontIndicatorsError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fFrontIndicator"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => SelectFrontIndicatorsChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Front Indicators Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleFrontIndicators}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fRearIndicator"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Rear Indicator Condition *
                                </label>
                                <select
                                    className={
                                        vehicleRearIndicatorsError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fRearIndicator"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => SelectRearIndicatorsChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Rear Indicators Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleRearIndicators}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fTailLamp"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Tail Lamp Condition *
                                </label>
                                <select
                                    className={
                                        vehicleTailLampError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fTailLamp"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => SelectTailLampChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Tail Lamp Condition
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleTailLamp}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fElectricalCondition"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Electrical Condition <span style={{ color: 'red' }}>**</span>
                                </label>
                                <select
                                    className={
                                        electricalConditionError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fElectricalCondition"
                                    placeholder="This is a search placeholder"
                                    value={reqCondition.id}
                                    name={reqCondition.name}
                                    onChange={(e) => selectElectricalConditionChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Electrical Condition
                                    </option>
                                    {reqCondition.map((test, index) => (
                                        <option
                                            selected={test.name === electricalCondition}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fToolkit"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Toolkit Available*
                                </label>
                                <select
                                    className={
                                        vehicleToolkitError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fToolkit"
                                    placeholder="This is a search placeholder"
                                    value={options.id}
                                    name={options.name}
                                    onChange={(e) => SelectVehicleToolkitChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose ToolKit Status
                                    </option>
                                    {options.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleToolkit}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fOtherAccessories"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Other Accessories Available*
                                </label>
                                <select
                                    className={
                                        vehicleOthersAccessoriesError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fOtherAccessories"
                                    placeholder="This is a search placeholder"
                                    value={options.id}
                                    name={options.name}
                                    onChange={(e) => SelectVehicleOthersAccessoriesChange(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Other Accessories Status
                                    </option>
                                    {options.map((test, index) => (
                                        <option
                                            selected={test.name === vehicleOthersAccessories}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fAccidentalStatus"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Accidental Status *
                                </label>
                                <select
                                    className={
                                        accidentalError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fAccidentalStatus"
                                    placeholder="This is a search placeholder"
                                    value={options.id}
                                    name={options.name}
                                    onChange={(e) => SelectAccidentalStatus(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Accidental Status
                                    </option>
                                    {options.map((test, index) => (
                                        <option
                                            selected={test.name === accidental}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fLevelOfMaintenance"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Level Of Maintenance *
                                </label>
                                <select
                                    className={
                                        maintenanceLevelError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fLevelOfMaintenance"
                                    placeholder="This is a search placeholder"
                                    value={condition.id}
                                    name={condition.name}
                                    onChange={(e) => SelectMaintenanceLevelCondition(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose Level Of Maintenance
                                    </option>
                                    {condition.map((test, index) => (
                                        <option
                                            selected={test.name === maintenanceLevel}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fOtherRepairStatus"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Other Repair Status *
                                </label>
                                <select
                                    className={
                                        otherRepairError === ""
                                            ? `border-default-200 w-full`
                                            : `border-default-200 w-full error_class`
                                    }
                                    data-trigger
                                    id="fOtherRepairStatus"
                                    placeholder="This is a search placeholder"
                                    value={options.id}
                                    name={options.name}
                                    onChange={(e) => SelectotherRepairStatus(e)}
                                >
                                    <option value="0" hidden selected>
                                        Choose other Repair Status
                                    </option>
                                    {options.map((test, index) => (
                                        <option
                                            selected={test.name === otherRepair}
                                            key={index}
                                            value={test.id}
                                            name={test.name}
                                            required
                                        >
                                            {test.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fExShowRoomPrice"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Your Remark On Vehicle *
                                </label>
                                <input
                                    type="text"
                                    className={
                                        `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                                    }
                                    id="fExShowRoomPrice"
                                    placeholder="Enter your remark"
                                    value={yourRemark}
                                    onChange={(e) => setYourRemark(e.target.value)}
                                />
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fExShowRoomPrice"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Ex-ShowRoom Price <span style={{ color: 'red' }}>**</span>
                                </label>
                                <input
                                    type="text"
                                    className={
                                        exShowRoomPriceError === '' ?
                                            `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                                            :
                                            `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                                    }
                                    id="fExShowRoomPrice"
                                    placeholder="Enter Ex-ShowRoom Price"
                                    value={exShowRoomPrice}
                                    onChange={(e) => setExShowRoomPrice(e.target.value)}
                                />
                            </div>
                            <div className="space-y-1">
                                <label
                                    htmlFor="fEstimatedPrice"
                                    className="text-sm font-medium text-default-900"
                                >
                                    Estimated Price <span style={{ color: 'red' }}>**</span>
                                </label>
                                <input
                                    type="text"
                                    className={
                                        estimatedPriceError === '' ?
                                            `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50`
                                            :
                                            `block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class`
                                    }
                                    id="fEstimatedPrice"
                                    placeholder="Enter Estimated Price"
                                    value={estimatedPrice}
                                    onChange={(e) => setEstimatedPrice(e.target.value)}
                                />
                                {
                                    depreciatedPrice ?
                                        <div>The recommended estimated price for the vehicle is <span style={{ color: 'red' }}>₹{depreciatedPrice}</span></div> : <></>
                                }
                            </div>
                        </div>
                        <div className="flex items-center gap-2 justify-end" style={{ marginBottom: '80px' }}>
                            <button
                                style={{ background: "#ef4444", color: '#fff', border: 'none', outline: 'none' }}
                                className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
                                onClick={() => navigation(`/admin/editreport3/${params.id}`)}
                            >
                                <i className="ph-bold ph-x text-lg" />Go Back
                            </button>
                            <button
                                className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                                style={{ background: "#4D44B5", border: 'none', outline: 'none' }}
                                onClick={() => handleSubmit()}
                            >
                                <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                                View Report
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThreeWheelerEditReport4

